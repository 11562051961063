import React, { useState } from "react"

import Table from "react-bootstrap/esm/Table"
import axios from "axios"
import Modal from "react-bootstrap/esm/Modal"
import Button from "react-bootstrap/esm/Button"
import Form from "react-bootstrap/esm/Form"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import { useNavigate } from "react-router-dom"
import "../CargaViaExcel.css"
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined"
import TagAcceptanceModal from "./TagAcceptanceModal"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import { formatJSON, handleFileChange } from "../utils"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const CargaViaExcelTags = () => {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [dataHeader, setDataHeader] = useState([])
	const [show, setShow] = useState(false)

	const [tagsToAccept, setTagsToAccept] = useState([])
	const [showAcceptanceModal, setShowAcceptanceModal] = useState(false)

	// Estados para Popup
	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	const handleClose = () => {
		setShow(false)
		setData([])
	}
	const handleShow = () => setShow(true)

	const generarArregloJSON = async () => {
		const jsonArr = formatJSON(data)

		try {
			const response = await axios({
				method: "post",
				url: `${BACK_APP_URI}/tags/sumarExcel`,
				data: {
					tagsData: jsonArr,
				},
			})

			setTagsToAccept(response.data)
			setShowAcceptanceModal(true)
			handleClose()
		} catch (error) {
			console.error(`Error updating data:`, error)
		}
	}

	const handleSuccessfulMessage = msg => {
		setVariable(true)
		setErrorBool(false)
		setAvisomsg(msg)
	}
	const handleErrorMessage = msg => {
		setVariable(true)
		setErrorBool(true)
		setAvisomsg(msg)
	}

	return (
		<>
			<button
				className="icon-filter"
				onClick={handleShow}
			>
				<DescriptionOutlinedIcon style={{ color: "#056a6c", backgroundColor: "#cce8e8" }} />
			</button>

			<Modal
				size="lg"
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<div className="modal-container">
					<Modal.Title className="modal-title-excel">Carga Mediante Excel</Modal.Title>
					<Modal.Body>
						<div>
							<Form.Group
								controlId="formFile"
								className="mb-3"
							>
								<Form.Control
									className="input-modal"
									type="file"
									onChange={e => {
										handleFileChange(e, setData, setDataHeader)
									}}
								/>
							</Form.Group>
						</div>
						{data.length > 0 ? (
							<>
								<div className="header-table-modal">
									<h2>Productos</h2>
									<div className="">
										<Table
											className=" table-striped table-bordered table-hover"
											responsive
										>
											<thead>
												<tr>
													{dataHeader.map((item, index) => (
														<th key={index}>{item}</th>
													))}
												</tr>
											</thead>
											<tbody>
												{data.slice(1).map((rowData, rowIndex) => (
													<tr key={rowIndex}>
														{rowData.map((cellData, cellIndex) => (
															<td key={cellIndex}>{cellData}</td>
														))}
													</tr>
												))}
											</tbody>
										</Table>
									</div>
								</div>
							</>
						) : null}
						<Modal.Footer>
							<div className="d-flex">
								{data.length > 0 ? (
									<Button
										style={{ width: "10rem", marginRight: "1rem" }}
										className="btn--primary"
										variant="primary"
										onClick={generarArregloJSON}
									>
										Cargar
									</Button>
								) : null}

								<Button
									variant="secondary"
									onClick={handleClose}
								>
									Cerrar
								</Button>
							</div>
						</Modal.Footer>
					</Modal.Body>
				</div>
			</Modal>

			<TagAcceptanceModal
				show={showAcceptanceModal}
				handleClose={() => setShowAcceptanceModal(false)}
				handleErrorMessage={handleErrorMessage}
				handleSuccessfulMessage={handleSuccessfulMessage}
				tagsToAccept={tagsToAccept}
			/>

			{/* Se agrega el pop-up */}
			{variable ? (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
					refreshPage={() => {
						window.location.reload()
					}}
				/>
			) : (
				<></>
			)}
		</>
	)
}

export default CargaViaExcelTags
