import { read, utils } from "xlsx"

export const handleFileChange = (e, setData, setDataHeader) => {
  const file = e.target.files[0]
  // Aca se crea una instancia de FileReader
  const reader = new FileReader() // FileReader es una interfaz en JavaScript que proporciona la funcionalidad para leer archivos y obtener su contenido

  reader.readAsArrayBuffer(file) //  se utiliza reader.readAsArrayBuffer(file) para leer el archivo seleccionado como un ArrayBuffer

  reader.onload = e => {
    // Leer los datos del archivo como un Uint8Array
    const data = new Uint8Array(e.target.result)

    // Leer el archivo como un libro de Excel utilizando una biblioteca externa (probablemente xlsx.js)
    const workbook = read(data, { type: "array" })

    // Obtener la primera hoja de cálculo del libro de Excel
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]

    // Convertir la hoja de cálculo a un objeto JSON utilizando una biblioteca externa (probablemente xlsx.js)
    const jsonData = utils.sheet_to_json(worksheet, { header: 1 })

    // Filtrar el objeto JSON para eliminar filas vacías o sin datos
    const arrayFiltrado = jsonData
      .filter(array => array.some(element => element !== undefined && element !== null && element !== ""))
      .map(row => {
        const newRow = Array.from(row, item => (item === undefined ? "" : item))

        if (row.length < jsonData[0].length) {
          for (let i = 0; i < jsonData[0].length - row.length; i++) {
            newRow.push("")
          }
        }

        return newRow
      })

    // Asignar los datos filtrados a una variable de estado llamada "data"
    setData(arrayFiltrado)

    // Asignar la segunda fila del conjunto de datos filtrados a una variable de estado llamada "dataHeader"
    setDataHeader(arrayFiltrado[0])
  }
}

export const formatJSON = (data) => {
  // Obtener los nombres de atributos de la primera fila
  const attributeNames = data[0]
  const arr = []

  // Iterar sobre las filas a partir de la segunda fila
  for (let i = 1; i < data.length; i++) {
    const rowData = data[i]
    const jsonItem = {}

    // Iterar sobre las columnas
    for (let j = 0; j < rowData.length; j++) {
      const attributeName = attributeNames[j]
        .replace(/\s/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase() // el .replace se pone para quitar espacios en el nombre y tildes
      const attributeValue = rowData[j]
      jsonItem[attributeName] = attributeValue
    }
    arr.push(jsonItem)
  }
  return arr
}