import axios from "axios"
import Accordion from "react-bootstrap/Accordion"
import Cookies from "js-cookie"
import "../Modals.css"
import React, { Fragment, useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import ListItemCheck from "../Components/ListItemCheck"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function AcceptAdditionModal({
	show,
	handleClose,
	productsToAcceptAddition,
	handleSuccessfulMessage,
	handleErrorMessage,
	tabSection,
	isProductWithoutLotOperation,
}) {
	const {
		productsWithNewLot,
		newProductsWithNewLot,
		productsWithLotToAddQuantity,
		productsWithPoorData,
		newProducts,
		productsExistent,
		productsOrLotsWithPoorData,
		productsToAddQuantity
	} = productsToAcceptAddition

	const [providerNames, setProvidersNames] = useState([])
	const [unitsNames, setUnitsNames] = useState([])
	const [tagsNames, setTagsNames] = useState([])
	const [categoriesNames, setCategoriesNames] = useState([])
	const [isLoading, setIsLoading] = useState(false) // Estado de carga del boton Aceptar suma


	useEffect(() => {
		; (async () => {
			// Obtiene todos los nombres de los proveedores
			const token = Cookies.get("token")
			const responseProvider = await axios({
				method: "get",
				url: `${BACK_APP_URI}/proveedor`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const providers = responseProvider.data.provider.map(provider => {
				return {
					id: provider._id,
					name: provider.name,
				}
			})

			setProvidersNames(providers)

			// Obtiene todas las unidades
			const responseUnit = await axios({
				method: "get",
				url: `${BACK_APP_URI}/unidades`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const units = responseUnit.data.units.map(unit => {
				return {
					id: unit._id,
					name: unit.name,
				}
			})

			setUnitsNames(units)

			// Obtiene todos los tags
			const responseTag = await axios({
				method: "get",
				url: `${BACK_APP_URI}/tags`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const tags = responseTag.data.tags.map(tags => {
				return {
					id: tags._id,
					name: tags.name,
				}
			})

			setTagsNames(tags)

			// Obtiene todos las categorías
			const responseCategories = await axios({
				method: "get",
				url: `${BACK_APP_URI}/categories`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const categories = responseCategories.data.categories.map(category => {
				return {
					id: category._id,
					name: category.name,
				}
			})

			setCategoriesNames(categories)
		})()
	}, [])

	const handleAddProducts = async () => {
		if (isLoading) return; // Evita múltiples clics si ya está cargando
		setIsLoading(true);
		try {

			if (tabSection === "Bioquimicos") {

				if (isProductWithoutLotOperation) {
					///////////////////// Creación de Producto sin lote /////////////////////

					// Nuevos Productos sin lote
					for (const product of newProducts) {
						const { productCode, name, providerId, unitTypeId, brand, ubn, tagId, weightOrVolume } = product

						// Se crea el producto
						await axios({
							method: "post",
							url: `${BACK_APP_URI}/productos/add`,
							data: {
								name: name,
								code: productCode || 1,
								brand: brand,
								unitType: unitTypeId,
								provider: providerId,
								ubn: ubn,
								tag: tagId,
								weightOrVolume: weightOrVolume
							},
						})
					}
				} else {
					///////////////////// Creación de Producto con lote /////////////////////

					// Nuevos Lotes
					for (const product of productsWithNewLot) {
						const { lotCode, acquireDate, expireDate, quantity, providerId, productId, lotQuantity } = product

						// Se crea el lote
						await axios({
							method: "post",
							url: `${BACK_APP_URI}/lotes/add`,
							data: {
								lotNumber: lotCode,
								expireDate: expireDate,
								acquireDate: acquireDate,
								quantity: quantity,
								provider: providerId,
								product: productId,
								lotQuantity: lotQuantity,
							},
						})
					}
					// Nuevos Productos con Lote
					for (const product of newProductsWithNewLot) {
						const { lotCode, acquireDate, expireDate, quantity, productCode, name, providerId, unitTypeId, brand, ubn, lotQuantity, tagId, weightOrVolume } = product

						// Se crea el producto
						const productResponse = await axios({
							method: "post",
							url: `${BACK_APP_URI}/productos/add`,
							data: {
								name: name,
								code: productCode || 1,
								brand: brand,
								unitType: unitTypeId,
								provider: providerId,
								ubn: ubn,
								tag: tagId,
								weightOrVolume: weightOrVolume
							},
						})

						// Se crea el lote
						await axios({
							method: "post",
							url: `${BACK_APP_URI}/lotes/add`,
							data: {
								lotNumber: lotCode,
								expireDate: expireDate,
								acquireDate: acquireDate,
								quantity: quantity,
								provider: providerId,
								product: productResponse.data.product._id,
								lotQuantity: lotQuantity,
							},
						})
					}

					// Lotes a editar
					for (const product of productsWithLotToAddQuantity) {
						const { lotCode, acquireDate, expireDate, quantity, providerId, oldQuantity, oldLotQuantity, lotId, productId, lotQuantity } = product

						await axios({
							method: "put",
							url: `${BACK_APP_URI}/lotes/edit/${lotId}`,
							data: {
								lotNumber: lotCode,
								expireDate: expireDate,
								acquireDate: acquireDate,
								quantity: quantity + oldQuantity, // Se suman cantidades viejas con las nuevas
								provider: providerId,
								product: productId,
								lotQuantity: lotQuantity + oldLotQuantity,
							},
						})
					}
				}

			} else {

				// Nuevos Productos Varios
				for (const product of newProducts) {
					const { productCode, name, providerId, unitTypeId, categoryId, tagId, weightOrVolume, quantity } = product


					// Se crea el producto
					await axios({
						method: "post",
						url: `${BACK_APP_URI}/otros_productos/add`,
						data: {
							name: name,
							code: productCode,
							unitType: unitTypeId,
							provider: providerId,
							category: categoryId,
							tag: tagId,
							quantity: quantity,
							weightOrVolume: weightOrVolume
						},
					})
				}

				// Varios a editar
				for (const product of productsToAddQuantity) {
					const { productCode, name, providerId, unitTypeId, category, tagId, weightOrVolume, quantity, productId, oldQuantity } = product

					// Se crea el producto
					await axios({
						method: "put",
						url: `${BACK_APP_URI}/otros_productos/edit/${productId}`,
						data: {
							name: name,
							code: productCode,
							unitType: unitTypeId,
							provider: providerId,
							category: category,
							tag: tagId,
							quantity: oldQuantity + quantity,
							weightOrVolume: weightOrVolume
						},
					})
				}

			}


			   handleSuccessfulMessage("Suma completa")
      handleClose()
    } catch (error) {
      handleErrorMessage("Error al sumar")
      handleClose()
	} finally {
		setIsLoading(false);
		handleClose();
	  }
  }

	const ModalSections = [
		// Productos sin lotes
		{
			title: "Nuevos Productos",
			products: newProducts,
			available: true,
		},
		{
			title: "Productos existentes",
			products: productsExistent,
			available: false,
		},
		{
			title: "Productos a Sumar Cantidad",
			products: productsToAddQuantity,
			available: true,
		},
		{
			title: "Datos insuficientes",
			products: productsWithPoorData,
			available: false,
		},

		// Productos con lotes
		{
			title: "Nuevos Lotes",
			products: productsWithNewLot,
			available: true,
			showLotData: true,
		},
		{
			title: "Nuevos Productos con Lote",
			products: newProductsWithNewLot,
			available: true,
			showLotData: true,
		},
		{
			title: "Lotes a Sumar Cantidad",
			products: productsWithLotToAddQuantity,
			available: true,
			showLotData: true,
		},
		{
			title: "Datos insuficientes",
			products: productsOrLotsWithPoorData,
			available: false,
			showLotData: true,
		},

	]
	console.log(categoriesNames)

	return (
		<Modal
			size="lg"
			show={show}
			onHide={handleClose}
			backdrop="static"
		>
			<div className="modal-container">
				<Modal.Header>
					<Modal.Title className="modal-title-small">Ingreso de Productos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ModalSections.map((section, index) => {
						// No se muestra si no tiene productos
						if (!section.products?.length > 0) return

						return (
							<Fragment key={section.title}>
								<div className={`${!section.available && "color-gray"} flex flex-column gap-2`}>
									<h3 className={`${section.available ? "market-green" : "market-red"} market`}>{section.title}</h3>
									{section.products?.map((product, index) => {
										return (
											<>
												<div key={product.productCode}>
													<Accordion defaultActiveKey="0">
														<Accordion.Item>
															<Accordion.Header>
																<div className="flex flex-column gap-2">
																	<div>
																		Producto: {product.productCode} - {product.name}
																	</div>
																	{section.title === "Lotes a Sumar Cantidad" && (
																		<>
																			<div>
																				Cantidad a Sumar: <b className="color-green">{product.quantity}</b>
																			</div>
																			<div>
																				Lotes a agregar: <b className="color-green">{product.lotQuantity}</b>
																			</div>
																		</>
																	)}
																	{section.title === "Productos a Sumar Cantidad" && (
																		<>
																			<div>
																				Cantidad a Sumar: <b className="color-green">{product.quantity}</b>
																			</div>
																		</>
																	)}
																</div>
															</Accordion.Header>
															<Accordion.Body>
																<ul>
																	{section.showLotData && (
																		<>
																			<ListItemCheck property={product.lotCode} title={"Código Lote:"} />
																			<li className={!product.acquireDate ? "color-red" : ""}>Fecha Ingreso: {product.acquireDate && new Date(product.acquireDate).toLocaleDateString()}</li>
																			<li className={!product.expireDate ? "color-red" : ""}>Fecha Vencimiento: {product.expireDate && new Date(product.expireDate).toLocaleDateString()}</li>
																			<li className={product.quantity === "" ? "color-red" : ""}>Cantidad: {product.quantity}</li>
																			<ListItemCheck property={product.lotQuantity} title={"Cantidad Lotes:"} />

																			<hr />
																		</>
																	)}
																	<ListItemCheck property={product.productCode} title={"Código Producto:"} />
																	<ListItemCheck property={product.name} title={"Nombre:"} />
																	<li className={!product.providerId ? "color-red" : ""}>Proveedor: {providerNames.find(provider => provider.id == product.providerId)?.name}</li>
																	<li className={!product.unitTypeId ? "color-red" : ""}>Tipo Unidad: {unitsNames.find(unit => unit.id == product.unitTypeId)?.name}</li>
																	{product.tagId !== null && (
																		<ListItemCheck property={tagsNames.find(tag => tag.id == product.tagId)?.name} title={"Etiqueta:"} />
																	)}
																	{product.weightOrVolume && (
																		<ListItemCheck property={product.weightOrVolume} title={"Peso o Volumen:"} />
																	)}
																	{tabSection === "Bioquimicos" ? (
																		<>
																			<ListItemCheck property={product.ubn} title={"Nbu:"} />
																			<ListItemCheck property={product.brand} title={"Marca:"} />
																		</>
																	) : (
																		<>
																			<li className={product.quantity === "" ? "color-red" : ""}>Cantidad: {product.quantity}</li>
																			<li className={!product.categoryId ? "color-red" : ""}>Categoría: {categoriesNames.find(category => category.id == product.categoryId)?.name}</li>
																		</>
																	)}
																</ul>
															</Accordion.Body>
														</Accordion.Item>
													</Accordion>
												</div>
											</>
										)
									})}
								</div>
								{index != ModalSections.length - 1 && <hr />}
							</Fragment>
						)
					})}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex">
						{productsWithNewLot?.length > 0 || newProductsWithNewLot?.length > 0 || productsWithLotToAddQuantity?.length > 0 || newProducts?.length > 0 || productsToAddQuantity?.length > 0 ? (
							 <Button
							 style={{ width: "10rem", marginRight: "1rem" }}
							 className="btn--primary"
							 variant="primary"
							 onClick={handleAddProducts}
							 disabled={isLoading} // Deshabilitar botón mientras se carga
						   >
							 {isLoading ? "Procesando..." : "Aceptar suma"}
						   </Button>
						 ) : null}
					 
						 <Button
						   variant="secondary"
						   onClick={handleClose}
						 >
							Cerrar
						</Button>
					</div>
				</Modal.Footer>
			</div>
		</Modal>
	)
}
