import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import CargaViaExcel from "../CargaViaExcel/Products/CargaViaExcel";
import "./abmButtons.css";
import { Form, Modal } from "react-bootstrap";
import pdfIcon from "./../../Assets/Img/pdf17.svg"
import iconAdd from "./iconButtonAbm/iconAdd2.svg";
import iconEdit from "./iconButtonAbm/iconEdit.svg";
import iconTrash from "./iconButtonAbm/iconTrash.svg";
import { Tooltip } from "@mui/material";
import deleteIcon from "./../../Assets/Img/delete-svgrepo-com.svg"
import { PDFDownloadLink } from "@react-pdf/renderer";
import ProviderProductsToPdf from "./../Products/ProviderProducts/ProviderProductsToPdf"
import Cookies from "js-cookie";
import axios from "axios";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

export default function AbmButtons(props) {
    const itemSelected = props.item;
    const abmProveniente = props.abm;
    const funcionAbmProveniente = props.funcion;
    const okDeleteAllProvProducts = props.okDeleteAllProvProducts;
    const isAdmin = props.role;
    const providers = props.providers;
    const isLoaded = props.isLoaded;

    const [show, setShow] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [selectProviderModal, setSelectProviderModal] = useState(false);
    const [selectProvProductsModal, setSelectProvProductsModal] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);

    //------------------------HANDLERS----------------------------------------
    const handleHide = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleSelectProvider = () => setSelectProviderModal(!selectProviderModal);
    const handleSelectProvProducts = () => setSelectProvProductsModal(!selectProvProductsModal);
    const handleCloseWarningModal = () => setWarningModal(!warningModal);

    const handleDeleteProvProducts = () => {
        setSelectProvProductsModal(!selectProvProductsModal);
        setWarningModal(!warningModal);
    }

    //-------------------------PDF Button---------------------------------------
    useEffect(() => {
        async function getDataProducts() {
            const token = Cookies.get("token");
            const result = await axios.get(`${BACK_APP_URI}/productos`, {
                headers: {
                    Authorization: `${token}`,
                },
            });

            const products = result.data.products;

            const otherProductsResponse = await axios.get(`${BACK_APP_URI}/otros_productos`, {
                headers: {
                    Authorization: `${token}`,
                },
            });

            const otherProducts = otherProductsResponse.data.products;

            const combinedProducts = [...products, ...otherProducts];

            const filteredProductsByProviderId = combinedProducts.filter(

                (product) => product.provider._id === selectedProvider
            );

            setFilteredProducts(filteredProductsByProviderId);
            //console.log("Filtered ", filteredProductsByProviderId);

        }
        getDataProducts();

    }, [selectedProvider])


    return (

        <div className="btn-position">
            {isAdmin !== "sede" ? (
                <>
                    {itemSelected ? (
                        <>
                            {abmProveniente === "productosVarios" ? (
                               <Tooltip title="Editar" arrow>
                               <Link to={`editVarios/${itemSelected}`}>
                                   <Button className="btn--primary--abm">
                                       <img className="icon_buttons" src={iconEdit} alt="Editar" />
                                   </Button>
                               </Link>
                           </Tooltip>
                                
                            ) : (

                                <Tooltip title="Editar" arrow>
                                <Link to={`edit/${itemSelected}`}>
                                    <Button className="btn--primary--abm">
                                        <img className="icon_buttons" src={iconEdit} alt="Editar" />
                                    </Button>
                                </Link>
                            </Tooltip>



                            )}
                        </>


                    ) : (
                        <Tooltip title="Editar" arrow>
                        <Button className="btn--primary--abm" disabled>
                            <img
                                className="icon_buttons"
                                src={iconEdit}
                                alt="Editar"
                            />
                        </Button>
                        </Tooltip>
                    )}

                    {/* Solo muestra boton de agregar si no es para productos ni productos varios */}
                    {abmProveniente !== "productos" && abmProveniente !== "productosVarios" ? (
                         <Tooltip title="Agregar" arrow>
                        <Link to={`add`}>
                            <Button className="btn--primary--abm">
                                <img
                                    className="icon_buttons"
                                    src={iconAdd}
                                    alt="Agregar"
                                />
                            </Button>
                        </Link>
                        </Tooltip>
                    ) : null}

                    {abmProveniente === "unidades" || itemSelected ? (
                        <Tooltip title="Eliminar" arrow>
                        <Button
                            className="btn--primary--abm"
                            onClick={handleHide}

                        >
                            <img
                                className="icon_buttons"
                                src={iconTrash}
                                alt="eliminar"
                            />
                        </Button>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Eliminar" arrow>
                        <Button
                            className="btn--primary--abm"
                            onClick={handleHide}
                            disabled

                        >
                            <img
                                className="icon_buttons"
                                src={iconTrash}
                                alt="eliminar"
                            />
                        </Button>
                        </Tooltip>
                    )}

                    {abmProveniente === "productos" || abmProveniente === "productosVarios" ? (
                         <Tooltip title="Exportar pdf" arrow>
                        <button
                            className="btn--primary--abm"
                            onClick={handleSelectProvider}
                        //disabled
                        >
                            <img
                                className="icon_buttons"
                                src={pdfIcon}
                                alt="pdf"
                            />
                        </button>
                        </Tooltip>
                    ) : null}

                    {(abmProveniente === "productos" || abmProveniente === "productosVarios") &&
                       <Tooltip title="Eliminar todos" arrow>
                        <button
                            className="btn--primary--abm"
                            onClick={handleSelectProvProducts}
                        >
                            <img
                                className="icon_buttons"
                                src={deleteIcon}
                                alt="delete"
                            />
                        </button>
                        </Tooltip>

                    }

                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        size="sm"
                    >
                        <Modal.Header>
                            <Modal.Title className="modal-title-abm">Eliminar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body-abm">
                            ¿Esta seguro que desea eliminar este item?
                        </Modal.Body>
                        <Modal.Footer
                            style={{ justifyContent: "space-between" }}
                        >
                            <Button variant="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => {
                                    funcionAbmProveniente() 
                                    handleClose()
                                }}
                            >
                                Eliminar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : null}

            {isAdmin === "sede" ? (
                <>
                    {abmProveniente === "pedidos" ? (
                        <Link to={`add`}>
                            <Button className="btn--primary--abm">
                                <img
                                    className="icon_buttons"
                                    src={iconAdd}
                                    alt="Agregar"
                                />
                            </Button>
                        </Link>
                    ) : null}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        size="sm"
                    >
                        <Modal.Header>
                            <Modal.Title>Eliminar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body-abm">
                            ¿Esta seguro que desea eliminar este item?
                        </Modal.Body>
                        <Modal.Footer
                            style={{ justifyContent: "space-between" }}
                        >
                            <Button variant="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => {
                                    funcionAbmProveniente() 
                                    handleClose()
                                }}
                            >
                                Eliminar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : null}

            {/* Warning Modal */}
            <Modal
                show={warningModal}
                onHide={handleCloseWarningModal}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title
                        style={{
                            color: "#d94343 ",
                            fontWeight: "bold",
                            margin: "auto",
                            fontSize: "1.75rem"
                        }}
                    >
                        Atención</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Esta acción <strong>no puede</strong> volverse atrás
                </Modal.Body>
                <Modal.Footer
                    style={{ justifyContent: "space-between" }}
                >
                    <Button variant="secondary" onClick={handleCloseWarningModal}>
                        Cancelar
                    </Button>
                    <Button
                        variant="danger"
                        onClick={()=> {
                            okDeleteAllProvProducts();
                            handleCloseWarningModal()}}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>

            </Modal>

            <Modal
                show={selectProviderModal}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title>Productos a pdf</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginBottom: "10px" }}>
                    Selecciona un proveedor
                    <Form style={{ marginTop: "10px" }}>
                        {isLoaded && (
                            <Form.Select
                                className="select-provider "
                                onChange={(e) => {
                                    return setSelectedProvider(e.target.value);
                                }}
                            >
                                <option value="">Proveedores</option>

                                {providers.map((provider) => (
                                    <option
                                        key={provider._id}
                                        value={provider._id}
                                    >
                                        {provider.name}
                                    </option>
                                ))}

                            </Form.Select>
                        )}
                    </Form>

                </Modal.Body>
                <Modal.Footer
                    style={{ justifyContent: "space-between" }}
                >

                    <Button variant="secondary" onClick={handleSelectProvider}>
                        Cancelar
                    </Button>
                    {/* Envío id de proveedor para generar PDF */}
                    <PDFDownloadLink
                        document={<ProviderProductsToPdf products={filteredProducts} />}
                        fileName={`Productos-Proveedor`}
                    >
                        <Button
                            variant="success"

                        >
                            Generar
                        </Button>
                    </PDFDownloadLink>
                </Modal.Footer>
            </Modal>


            {/* MODAL PARA BORRADO MASIVO DE PRODUCTOS */}
            <Modal
                show={selectProvProductsModal}
                backdrop="static"
                keyboard="false"
                centered
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title className="modal-title-abm">
                        Eliminar <strong>todos</strong> los productos</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginBottom: "10px" }}>
                    Selecciona proveedor
                    <Form style={{ marginTop: "10px" }}>
                        {isLoaded && (
                            <Form.Select
                                className="select-provider mb-3"
                                onChange={(e) => {
                                    setSelectedProvider(e.target.value);
                                    props.onProviderChange(e.target.value);
                                }}
                            >
                                <option value="">Proveedores</option>

                                {providers.map((provider) => (
                                    <option
                                        key={provider._id}
                                        value={provider._id}
                                    >
                                        {provider.name}
                                    </option>
                                ))}

                            </Form.Select>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer
                    style={{ justifyContent: "space-between" }}
                >
                    <Button
                        className="btn-sencodary"
                        variant="secondary"
                        onClick={handleSelectProvProducts}

                    >
                        Cancelar
                    </Button>
                    <Button
                        className="btn-danger"
                        variant="danger"
                        onClick={handleDeleteProvProducts}

                    >
                        Borrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
