import axios from "axios"
import Cookies from "js-cookie"
import React, { Fragment, useEffect, useState } from "react"
import { Accordion, Button, Modal } from "react-bootstrap"
import ListItemCheck from "../Components/ListItemCheck"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function UserAcceptanceModal({ show, handleClose, usersToAccept, handleSuccessfulMessage, handleErrorMessage }) {
	const { newUsers, usersToEdit, usersWithPoorData } = usersToAccept

	const handleUpdateUsers = async () => {
		try {

			for (const user of newUsers) {

				let dataToSend = user

				if(user.userType === "USUARIO") {
					dataToSend = { ...user, productsAllowed_biochemicalRole: user.productsAllowed.filter(product => !product.tag) , productTagsAllowed_biochemicalRole: user.productsAllowed.filter(product => product.tag)}
				}
				await axios({
					method: "post",
					url: `${BACK_APP_URI}/users/add`,
					data: dataToSend,
				})
			}

			for (const user of usersToEdit) {
				
				let dataToSend = user

				if(user.userType === "USUARIO") {
					dataToSend = { ...user, productsAllowed_biochemicalRole: user.productsAllowed.filter(product => !product.tag) , productTagsAllowed_biochemicalRole: user.productsAllowed.filter(product => product.tag)}
				}
				await axios({
					method: "put",
					url: `${BACK_APP_URI}/users/edit/${user._id}`,
					data: dataToSend,
				})
			}

			handleSuccessfulMessage("Edición completa")
			handleClose()
		} catch (error) {
			handleErrorMessage("Error al editar")
			handleClose()
		}
	}

	const ModalSections = [
		{
			title: "Nuevos Usuarios",
			data: newUsers,
			available: true,
		},
		{
			title: "Usuarios a Editar",
			data: usersToEdit,
			available: true,
		},
		{
			title: "Datos insuficientes",
			data: usersWithPoorData,
			available: false,
		},
	]

	return (
		<Modal
			size="lg"
			show={show}
			onHide={handleClose}
			backdrop="static"
		>
			<Modal.Header>
				<Modal.Title className="italic">Creación de Usuarios</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ModalSections.map((section, index) => {
					// No se muestra si no tiene productos
					if (!section.data?.length > 0) return

					return (
						<div key={section.title}>
							<div className={`${!section.available && "color-gray"} flex flex-column gap-2`}>
								<h3 className={`${section.available ? "market-green" : "market-red"} market`}>{section.title}</h3>

								{section.data?.map(item => {
									return (
										<div key={item.fname}>
											<Accordion defaultActiveKey="0">
												<Accordion.Item>
													<Accordion.Header>
														<div className="flex flex-column gap-2">
															<div>
																Usuario: {item.fname} {item.lname}
															</div>
														</div>
													</Accordion.Header>
													<Accordion.Body>
														<ul>
															<ListItemCheck
																property={item.fname}
																title={"Nombre:"}
															/>
															<ListItemCheck
																property={item.lname}
																title={"Apellido:"}
															/>
															<ListItemCheck
																property={item.position}
																title={"Cargo:"}
															/>
															<ListItemCheck
																property={item.username}
																title={"Nombre de Usuario:"}
															/>
															<ListItemCheck
																property={item.password}
																title={"Contraseña:"}
															/>
															<li
																className={
																	item.userType !== "ADMINISTRADOR" && item.userType !== "USUARIO" && item.userType !== "INVENTARIO" ? "color-red" : ""
																}
															>
																Tipo de Usuario: {item.userType}
															</li>
															{(item.userType === "USUARIO" || item.userType === "INVENTARIO") && (
																<li className={item?.labs.some(lab => lab.found === false) ? "color-red" : ""}>Laboratorios: {item?.labs?.map(lab => lab.name + ", ")}</li>
															)}
															{item.userType === "USUARIO" && (
																<>
																	<li className={item?.categoriesAssigned?.some(cat => cat.found === false) ? "color-red" : ""}>
																		Categorías: {item?.categoriesAssigned?.map(cat => cat.name + ", ")}
																	</li>
																	<li className={item?.productsAllowed.some(product => product.found === false) ? "color-red" : ""}>
																		Bioquímicos Habilitados: {item?.productsAllowed?.map(product => product.name + ", ")}
																	</li>
																</>
															)}
														</ul>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</div>
									)
								})}
							</div>
							{index != ModalSections.length - 1 && <hr />}
						</div>
					)
				})}
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex">
					{newUsers?.length || usersToEdit?.length ? (
						<Button
							style={{ width: "10rem", marginRight: "1rem" }}
							className="btn--primary"
							variant="primary"
							onClick={handleUpdateUsers}
						>
							Aceptar cambios
						</Button>
					) : null}
					<Button
						variant="secondary"
						onClick={handleClose}
					>
						Cerrar
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
