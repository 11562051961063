import React, { useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap"
import axios from "axios"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function EditPasswordModal({ userId }) {
	const [showModal, setShowModal] = useState(false)
	const [password, setPassword] = useState("")
	const [showErrorMSG, setShowErrorMSG] = useState(false)

  const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	const handleClose = () => {
		setShowModal(false)
    setPassword("")
    setShowErrorMSG(false)
	}

	const handleEditPassword = async () => {
		try {
			if (password.length < 8) {
				setShowErrorMSG(true)
        return
			}

			const response = await axios({
				method: "put",
				url: `${BACK_APP_URI}/users/editPassword/${userId}`,
				data: {
					password: password,
				},
			})

      handleClose()
      setVariable(true)
      setErrorBool(false)
      setAvisomsg(response.data)
      setTimeout(() => {
        setVariable(false)
      }, 1400)

			console.log(response)
		} catch (error) {
      setVariable(true)
      setErrorBool(false)
      setAvisomsg("Error al editar contraseña")
		}
	}
	return (
		<div
			style={{
				display: "flex",
			}}
		>
			<Button className= 'btn-password'onClick={() => setShowModal(true)}>Cambiar Contraseña</Button>

			<Modal
				show={showModal}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>Cambiar Contraseña</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FloatingLabel
						label="Nueva contraseña"
						className="mb-3"
					>
						<Form.Control
							className="input--primary"
							placeholder="Contraseña"
							name="password"
							type="text"
							onChange={e => {
								setPassword(e.target.value)
							}}
						/>
					</FloatingLabel>

          {showErrorMSG && (
            <div class="error">Cantidad minima de caracteres de 8</div>
          )}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={handleEditPassword}
					>
						Guardar
					</Button>
				</Modal.Footer>
			</Modal>

      {variable && (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
				/>
			)}
		</div>
	)
}
