import React, { useState } from "react"
import iconRemove from "../../../Assets/Img/icon_remove_c.svg";
import IconAdd from "../../../Assets/Img/icon_add_c.svg";
import { Form, Table } from "react-bootstrap"
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';


export default function ProductsTable({
	productsArr,
	setProductsArr,
	onButtonClick,
	productsManuallyAddedArr,
	setProductsManuallyAdded,
	handleRemoveManuallyAddedProduct,
	isBiochemicalsSection, 
	isInStockTable,
	productsPerPage,
}) {
	const TrComponent = ({ product, onChange, onClick, isGreenBackground }) => {
        const [localQuantity, setLocalQuantity] = useState(product.quantityToOrder || "");
     
        const handleLocalChange = (e) => {
          const value = e.target.value;
          setLocalQuantity(value);
        };
     
        const handleBlur = () => {
          // Se actualiza el estado cuando el usuario termina de interactuar
          onChange(localQuantity);
        };
     
        const handleKeyPress = (e) => {
          if (e.key === 'Enter') {
            onChange(localQuantity);
          }
        };
     
        return (
          <tr
            style={{ backgroundColor: isGreenBackground ? "#ade4c0" : "" }}
            key={product._id}
          >
            <td>{product?.code || "-"}</td>
            <td>{product.name || "-"}</td>
            {isBiochemicalsSection && (
              <>
                <td>{product.ubn || "-"}</td>
                <td>{product.brand || "-"}</td>
              </>
            )}
            {!isInStockTable && (
              <>
                <td>
                  <input
                    className="w-50"
                    type="number"
                    min="0"
                    max="99999"
                    name={product._id}
                    value={localQuantity}
                    onChange={handleLocalChange}
                    onBlur={handleBlur}
                    onKeyPress={handleKeyPress} // se actualiza cuando presionamos enter
                  />
                </td>
						<td>{product?.measure?.name || product?.unitType?.name}</td>
					</>
				)}

				<td>{product?.provider?.name || product.selectedProviderName}</td>
				<td>
				<Tooltip title={!isInStockTable ? "Eliminar producto" : "Agregar producto"} arrow>
						<button
							className="btn-new-provider"
							onClick={() => onClick(product._id)}
						>
							<img
								className="icon-new-provider"
								src={!isInStockTable ? iconRemove : IconAdd}
								alt={!isInStockTable ? "Eliminar producto" : "Agregar producto"}
							></img>
						</button>
					</Tooltip>
				</td>
			</tr>
		)
	}

	const [search, setSearch] = useState("")

	const [pageNumber, setPageNumber] = useState(0)
	const pagesVisited = productsPerPage ? pageNumber * productsPerPage : 0

	const handlePageChange = isAddition => {
		if (isAddition) {
			setPageNumber(prev => prev + 1)
		} else if (!isAddition && pageNumber - 1 >= 0) {
			setPageNumber(prev => prev - 1)
		}
	}

	return (
		<div className="w-100 table-responsive">
			<div className="bg-primary-light">
				<div className="border-b-table p-2 flex justify-between align-center">
					<Form.Control
						className="p-2"
						type="text"
						value={search}
						onChange={e => {
							setSearch(e.target.value)
							setPageNumber(0)
						}}
						placeholder="Buscar..."
					/>
					<div className="flex gap-2">
						{pageNumber !== 0 ? <button className="btn-page" onClick={() => handlePageChange(false)}> <KeyboardArrowLeftIcon/></button> : null}
						<div >{pageNumber + 1}</div>
						{productsPerPage * (pageNumber + 1) < productsArr.length ? <button className="btn-page" onClick={() => handlePageChange(true)}>
							<KeyboardArrowRightIcon/> </button> : null}
					</div>
				</div>
			</div>
			<Table className="mb-3 rounded">
				<thead >
					<tr>
						<th>Código</th>
						<th>Producto</th>
						{isBiochemicalsSection && (
							<>
								<th>Nbu</th>
								<th>Marca</th>
							</>
						)}
						{!isInStockTable && <th>Cantidad</th>}
						{!isInStockTable && <th>Medida</th>}
						<th>Proveedor</th>
						{!isInStockTable ? <th>Remover</th> : <th>Agregar</th>}
					</tr>
				</thead>
				<tbody>
					{productsArr?.length > 0 &&
						productsArr
							.filter(product => product.name.toLowerCase().includes(search.toLowerCase()))
							.slice(pagesVisited, pagesVisited + productsPerPage)
							.map(product => {
								return (
									<TrComponent
										product={product}
										onChange={value =>
											setProductsArr(prev =>
												prev.map(prevProduct => {
													if (prevProduct._id === product._id) {
														return {
															...prevProduct,
															quantityToOrder: value,
														}
													} else {
														return prevProduct
													}
												})
											)
										}
										onClick={() => onButtonClick(product._id)}
									/>
								)
							})}
					{productsManuallyAddedArr?.length > 0 &&
						productsManuallyAddedArr.map(product => {
							return (
								<TrComponent
									isGreenBackground
									product={product}
									onChange={value =>
										setProductsManuallyAdded(prev =>
											prev.map(prevProduct => {
												if (prevProduct._id === product._id) {
													return {
														...prevProduct,
														quantityToOrder: value,
													}
												} else {
													return prevProduct
												}
											})
										)
									}
									onClick={() => handleRemoveManuallyAddedProduct(product._id)}
								/>
							)
						})}
				</tbody>
			</Table>
		</div>
	)
}
