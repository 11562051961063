import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import "./EditUnits.css";
import Cookies from "js-cookie";

import { useForm } from "react-hook-form";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const EditUnits = () => {
  const { id } = useParams();
  const [isAdmin, setIsAdmin] = React.useState("");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [relationcode, setRelationCode] = useState("");
  const [allUnits, setAllUnits] = useState([]); 

  const updateUnit = (name, relationcode) => {
    axios({
      method: "post",
      url: `${BACK_APP_URI}/unidades/edit/${id}`,
      data: {
        name: name,
        relationcode: relationcode,
      },
    });
  }; 

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = () => {
    updateUnit(name, relationcode);
    navigate("/unidades");
  };

  React.useEffect(() => {
    const token = Cookies.get("token");
    async function getDataUnit() {
      await axios
        .get(`${BACK_APP_URI}/unidades/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((result) => {
          setName(result.data.name);
          setValue("name", result.data.name);
          setRelationCode(result.data.relationcode);
          setValue("relationcode", result.data.relationcode);
          setIsAdmin(result.data.userdata);
        });
    }
    getDataUnit();
  }, [id, setValue]);

  React.useEffect(() => {
    const token = Cookies.get("token");
    async function getDataUnits() {
      await axios
        .get(`${BACK_APP_URI}/unidades`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((result) => {
          setAllUnits(result.data.units);
          setIsAdmin(result.data.userdata);
        });
    }
    getDataUnits();
  }, []);

  return (
    <div className="main-container">
      <div className="edit-unit-header">
        <h1>Editar Unidades</h1>
        <div className="divider-tags"></div>
        <div className="edit-unit-card">
          <h1 className="h1-unidad">Unidad</h1>
          <Form className="edit-tag-floating" onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel label="Nombre" className="mb-3">
              <Form.Control
                value={name}
                className="edit-tag-form-control"
                placeholder="name"
                name="name"
                type="text"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                  pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                })}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {errors.name?.type === "required" && (
                <p class="error">Campo requerido </p>
              )}
              {errors.name?.type === "maxLength" && (
                <p class="error">Cantidad minima de caracteres es de 40</p>
              )}
              {errors.name?.type === "pattern" && (
                <p class="error">Solo puede contener letras</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Tipo de unidad" className="mb-3">
              <Form.Select
                value={relationcode}
                className="select-new"
                {...register("unit._id", {
                  required: true,
                })}
                onChange={(e) => {
                  setRelationCode(e.target.value);
                }}
              >
                {allUnits.map((unit) => {
                  return (
                    <option key={unit._id} value={unit._id}>
                      {unit.name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors.unitType?.type === "required" && (
                <p class="error">Campo requerido </p>
              )}
            </FloatingLabel>
            <div className="edit-tag-btn" >
            <div className="btn-edit">
            <button
                children="Cancelar"
                type="submit"
                className="button-edit-text"
                onClick={() => {
                  navigate("/unidades");
                }}
              />
              <button
                children="Guardar"
                type="submit"
                className="button-edit"
                
              />
              </div>
              </div>
          </Form>
        </div>

        <div />
      </div>
    </div>
  );
};

export default EditUnits;
