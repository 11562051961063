import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "./AddUnits.css";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const AddUnit = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [relationcode, setRelationcode] = useState("");
  const [allUnits, setAllUnits] = useState([]);
  const [isAdmin, setIsAdmin] = React.useState('')

  const addUnit = (name, relationcode) => {
    axios({
      method: "post",
      url: `${BACK_APP_URI}/unidades/add`,
      data: {
        name: name,
        relationcode: relationcode,
      },
    });
  };
 
  React.useEffect(() => {
    const token = Cookies.get('token');
    async function getDataUnits() {
      await axios.get(`${BACK_APP_URI}/unidades`, {
        headers: {
          Authorization: `${token}`
        }}).then((result) => {
        setAllUnits(result.data.units);
        setIsAdmin(result.data.userdata)
      });
    }
    getDataUnits();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    addUnit(name, relationcode);
    navigate("/unidades");
  };

  return (
    <div className="main-container">
      <div className="add-unit-header">
        <h1>Agregar Unidades</h1>
        <div className="divider-tags"></div>
        <div className="add-unit-target">
          <h1 className="h1-unidad">Unidad</h1>
          <Form className="edit-tag-floating" onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel label="Nombre" className="mb-3">
              <Form.Control
                className="edit-tag-form-control"
                placeholder="name"
                name="name"
                type="text"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                  pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                })}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {errors.name?.type === "required" && (
                <p class="error">Campo requerido</p>
              )}
              {errors.name?.type === "maxLength" && (
                <p class="error">Cantidad minima de caracteres es de 40</p>
              )}
              {errors.name?.type === "pattern" && (
                <p class="error">Solo puede contener letras</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Tipo de unidad" className="mb-3">
              <Form.Select
                className="select-new"
                {...register("unitType._id", {
                  required: true,
                })}
                onChange={(e) => {
                  setRelationcode(e.target.value);
                }}
              >
                <option value="">Seleccionar</option>
                {allUnits.map((relationcode) => {
                  return (
                    <option key={relationcode._id} value={relationcode._id}>
                      {relationcode.name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors.unitType?.type === "required" && (
                <p class="error">Campo requerido </p>
              )}
            </FloatingLabel>
            <div className="button-add-unit" >
            <button
                children="Cancelar"
                type="submit"
                className="button-edit-text"
                onClick={() => {
                  navigate("/unidades");
                }}
              />
              <button
                children="Guardar"
                type="submit"
                className="button-edit"
                
              />
          </div>
          </Form>
          </div>
          
        

        <div />
      </div>
    </div>
  );
};
export default AddUnit;
