import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import Card from "react-bootstrap/Card";
import IconAdd from "../../../Assets/Img/icon_add_c.svg";

import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful";
import { Button, CloseButton, InputGroup } from "react-bootstrap";
import "../Laboratories.css";
import { Chip, Tooltip } from "@mui/material"; 

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

export default function LabsForm(props) {
	const navigate = useNavigate();

	const [name, setName] = useState("");
	const [officeNumber, setOfficeNumber] = useState("");
	const [address, setAddress] = useState("");
	const [productsAdded, setProductsAdded] = useState([]); // Productos elegidos para agregar
	const [otherProductsAdded, setOtherProductsAdded] = useState([]); // Otros productos agregados
	const [productTagsAdded, setProductTagsAdded] = useState([]); // Tags elegidos para agregar
	const [otherProductTagsAdded, setOtherProductTagsAdded] = useState([]); // Otros tags elegidos

	const [allProducts, setAllProducts] = useState([]);
	const [allProductsTags, setAllProductsTags] = useState([]);
	const [allOtherProducts, setAllOtherProducts] = useState([]);
	const [allOtherProductsTags, setAllOtherProductsTags] = useState([]);

	const [productInputValue, setProductInputValue] = useState(""); // Guarda el valor del input productos
	const [otherProductInputValue, setOtherProductInputValue] = useState(""); // Guarda el valor del input otros productos
	const [categoryInputValue, setCategoryInputValue] = useState(""); // Guarda el valor del input categorías
	const [searchValueAdded, setSearchValueAdded] = useState(""); // Valor del buscador para productos agregados

	const [allCategories, setAllCategories] = useState([]); // Categorías disponibles
	const [selectedCategories, setSelectedCategories] = useState([]); // Categorías seleccionadas
	const [showAllCategories, setShowAllCategories] = useState(false); // Mostrar todas las categorías
	const [enableCategorySelection, setEnableCategorySelection] = useState(false); // Habilitar selección de categorías
	const [showAddByCategory, setShowAddByCategory] = useState(false); // Estado para "Agregar por categoría"
	const [relatedProducts, setRelatedProducts] = useState([]);
	const [relatedTags, setRelatedTags] = useState([]);

	const [variable, setVariable] = useState(false);
	const [errorBool, setErrorBool] = useState(false);
	const [avisomsg, setAvisomsg] = useState("");

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		(async () => {
			setName(props?.name || "");
			setOfficeNumber(props?.officeNumber || "");
			setAddress(props?.address || "");
			setProductsAdded(props?.productsAdded || []);
			setOtherProductsAdded(props?.otherProductsAdded || []);
			setProductTagsAdded(props?.productTagsAdded || []);
			setOtherProductTagsAdded(props?.otherProductTagsAdded || []);
			if (props?.name) setValue("name", props.name);
			if (props?.officeNumber) setValue("officeNumber", props.officeNumber);
			if (props?.address) setValue("address", props.address);
		})();
	}, [props, setValue]);

	useEffect(() => {
		(async () => {
			try {
				const token = Cookies.get("token");
				const responseCategories = await axios({
					method: "get",
					url: `${BACK_APP_URI}/categories`,
					headers: {
						Authorization: `${token}`,
					},
				});

				setAllCategories(responseCategories.data.categories);

				const responseTags = await axios({
					method: "get",
					url: `${BACK_APP_URI}/tags`,
					headers: {
						Authorization: `${token}`,
					},
				});
				setAllProducts(responseTags.data.tags.find((tag) => tag.name === "-").products);
				setAllOtherProducts(responseTags.data.tags.find((tag) => tag.name === "-").otherProducts);
				setAllProductsTags(responseTags.data.tags.filter((tag) => tag.isProducts));
				setAllOtherProductsTags(responseTags.data.tags.filter((tag) => tag.isProducts === false));
				console.log("Other Product Tags:", allOtherProductsTags);
			} catch (error) {
				console.log(error);
			}
		})();
	}, []);

		// Agregar productos y tags relacionados cuando se seleccionan categorías
	useEffect(() => {
	// Filtrar productos relacionados
	const filteredProducts = allOtherProducts.filter((product) =>
		selectedCategories.some((category) => category._id === product.category._id)
	);

	setRelatedProducts(filteredProducts); // Actualizar los productos relacionados

	// Filtrar tags relacionados
	const filteredTags = allOtherProductsTags.filter((tag) =>
		selectedCategories.some((category) => category._id === tag.category || category._id === tag.category._id)
	);

	setRelatedTags(filteredTags); // Actualizar los tags relacionados
	}, [selectedCategories, allOtherProducts, allOtherProductsTags]); // Dependencia en selectedCategories, allOtherProducts y allOtherProductTags


	const onSubmit = async () => {
		try {
			if (!name || !officeNumber || !address) {
				alert("Completa todos los campos");
				return;
			}
			if (
				productsAdded.length === 0 &&
				otherProductsAdded.length === 0 &&
				productTagsAdded.length === 0 &&
				otherProductTagsAdded.length === 0
			) {
				alert("Debe seleccionar al menos un producto o etiqueta");
				return;
			}

			if (props.type === "addLabs") {
				const response = await axios.post(`${BACK_APP_URI}/laboratory/add`, {
					name: name,
					officeNumber: officeNumber,
					address: address,
					products: productsAdded,
					otherProducts: otherProductsAdded,
					tags: [...productTagsAdded, ...otherProductTagsAdded],
				});
				setAvisomsg("Laboratorio creado correctamente");
			} else if (props.type === "editLabs") {
				const response = await axios({
					method: "post",
					url: `${BACK_APP_URI}/laboratory/edit/${props.id}`,
					data: {
						name: name,
						officeNumber: officeNumber,
						address: address,
						products: productsAdded,
						otherProducts: otherProductsAdded,
						tags: [...productTagsAdded, ...otherProductTagsAdded],
					},
				});
				setAvisomsg("Laboratorio editado correctamente");
			}
			setVariable(true);
			setErrorBool(false);
			setTimeout(() => {
				setVariable(false);
				navigate("/laboratory");
			}, 1200);
		} catch (error) {
			setErrorBool(true);
			setVariable(true);
			setTimeout(() => {
				setVariable(false);
				setAvisomsg(error.response.data.msg);
			}, 1200);
			console.log("Error al agregar el laboratorio:");
		}
	};


		// Filtrar productos y tags agregados para la búsqueda
	const filteredProductsAdded = productsAdded.filter((product) =>
		product.name.toLowerCase().includes(searchValueAdded.toLowerCase())
	);
	const filteredOtherProductsAdded = otherProductsAdded.filter((product) =>
		product.name.toLowerCase().includes(searchValueAdded.toLowerCase())
	);
	
	// Filtrar tags agregados para la búsqueda
	const filteredTagsAdded = otherProductTagsAdded.filter((tag) =>
		tag.name.toLowerCase().includes(searchValueAdded.toLowerCase())
	);
	
	// Eliminación de productos relacionados
	const handleDeleteRelatedProduct = (product) => {
		setRelatedProducts((prev) => prev.filter((p) => p._id !== product._id)); // Filtra y elimina el producto
	};
	
	// Eliminación de tags relacionados
	const handleDeleteRelatedTag = (tag) => {
		setRelatedTags((prev) => prev.filter((t) => t._id !== tag._id)); // Filtra y elimina el tag
	};
	


	// Agregar productos relacionados a la lista de productos agregados
	const confirmRelatedProductsAndTags = () => {
		
		// Filtrar productos de bioquímicos que no están ya en productos agregados
		const newBioquimicos = relatedProducts.filter(
			(product) => !productsAdded.some((p) => p._id === product._id) && allProducts.some((p) => p._id === product._id)
		  );
		
		  // Filtrar productos de varios que no están ya en otros productos agregados
		  const newVarios = relatedProducts.filter(
			(product) => !otherProductsAdded.some((p) => p._id === product._id) && allOtherProducts.some((p) => p._id === product._id)
		  );
		
		  // Filtrar tags que no están ya en los tags agregados
		  const newVariosTags = relatedTags.filter(
			(tag) => !otherProductTagsAdded.some((t) => t._id === tag._id) && allOtherProductsTags.some((t) => t._id === tag._id)
		  );
		  ;
		
		  // Añadir productos de bioquímicos a productos agregados
		  setProductsAdded((prev) => [...prev, ...newBioquimicos]);
		  // Añadir productos de varios a otros productos agregados
		  setOtherProductsAdded((prev) => [...prev, ...newVarios]);
		  // Añadir tags a otros tags agregados
		  setOtherProductTagsAdded((prev) => [...prev, ...newVariosTags]);
		
		  // Limpiar la lista de productos y tags relacionados
		  setRelatedProducts([]);
		  setRelatedTags([]);
		
	};


	// Para almacenar categorías seleccionadas
	const handleCategorySelection = (category) => {
		setSelectedCategories((prevSelected) => {
			if (prevSelected.some((selected) => selected._id === category._id)) {
				return prevSelected.filter((selected) => selected._id !== category._id);
			}
			return [...prevSelected, category];
		});
	};



//Para desactivar o activar las opciones 
	const handleEnableCategorySelection = (isChecked, type) => {
		if (type === "selectAll") {
			if (isChecked) {
				setSelectedCategories(allCategories);
				setShowAddByCategory(false); 
			} else {
				setSelectedCategories([]); 
			}
			setEnableCategorySelection(isChecked);
		} else if (type === "addByCategory") {
			if (isChecked) {
				
				setSelectedCategories([]);
			}
			setEnableCategorySelection(isChecked);
			setShowAddByCategory(isChecked); 
		}
	};


	return (
		<div className="main-container">
			<div className="header-edit-lab">
				<h1>{props.type === "addLabs" ? "Agregar Laboratorio" : "Editar Laboratorio"}</h1>
				<div className="divider-lab" />
				<div className="content-edit-lab"> 
					<h2>Laboratorio</h2>
					<Form className="form-edit-lab" onSubmit={handleSubmit(onSubmit)}>
						<FloatingLabel label="Nombre" className="mb-3">
							<Form.Control
								className="input-primary"
								placeholder="Nombre"
								value={name}
								type="text"
								{...register("name", {
									required: "Este campo es requerido",
								})}
								onChange={(e) => setName(e.target.value)}
							/>
							{errors.name && <span className="error">{errors.name.message}</span>}
						</FloatingLabel>
						<FloatingLabel label="Número de Sede" className="mb-3">
							<Form.Control
								className="input-primary"
								placeholder="Número de Sede"
								value={officeNumber}
								type="number"
								min="1"
								{...register("officeNumber", {
									required: "Este campo es requerido",
								})}
								onChange={(e) => setOfficeNumber(e.target.value)}
							/>
							{errors.officeNumber && <span className="error">{errors.officeNumber.message}</span>}
						</FloatingLabel>
						<FloatingLabel label="Dirección" className="mb-3">
							<Form.Control
								className="input-primary"
								placeholder="Dirección"
								value={address}
								type="text"
								{...register("address", {
									required: "Este campo es requerido",
								})}
								onChange={(e) => setAddress(e.target.value)}
							/>
							{errors.address && <span className="error">{errors.address.message}</span>}
						</FloatingLabel>

						{/* Buscador bioquímicos */}
						<Card className="input--primary mb-3" style={{ width: "100%" }}>
							<Card.Body>
								<Card.Text>Agregar Bioquímicos</Card.Text>
								<Form.Control
								className="input-primary"
								placeholder="Buscar Productos o Etiquetas..."
								type="text"
								style={{
									marginBottom: ".5rem",
								}}
								value={productInputValue}
								onChange={(e) => setProductInputValue(e.target.value)}
								/>
								<div style={{ maxHeight: "400px", overflowY: "auto" }}>
								{allProducts.map((product) => {
									if (productsAdded && productsAdded.some((productAdded) => productAdded.name === product.name)) return null;
									if (productInputValue === "" || !product.name.toLowerCase().includes(productInputValue.toLowerCase()))
									return null;

									return (
									<InputGroup style={{ flexWrap: "nowrap" }} key={product.name}>
										<InputGroup.Checkbox onChange={() => setProductsAdded((prev) => [...prev, product])} />
										<InputGroup.Text
										style={{
											whiteSpace: "normal",
											width: "100%",
											justifyContent: "center",
										}}
										>
										{product.name}
										</InputGroup.Text>
									</InputGroup>
									);
								})}

								{/* Mostrar los tags relacionados */}
								{allProductsTags.map((tag) => {
									// Ocultar tags ya seleccionados
									if (productTagsAdded && productTagsAdded.some((tagAdded) => tagAdded.name === tag.name)) return null;
									if (productInputValue === "" || !tag.name.toLowerCase().includes(productInputValue.toLowerCase())) return null;

									return (
									<div key={tag._id} style={{ display: "flex", alignItems: "center" }}>
										<InputGroup.Checkbox onChange={() => setProductTagsAdded((prev) => [...prev, tag])} />
										<InputGroup.Text
										style={{
											whiteSpace: "normal",
											width: "100%",
											justifyContent: "center",
										}}
										>
										{tag.name}
										</InputGroup.Text>
									</div>
									);
								})}
								</div>
							</Card.Body>
							</Card>



						{/* Buscador Varios */}
						<Card className="input--primary mb-3" style={{ width: "100%" }}>
							<Card.Body>
								<Card.Text>Agregar Varios</Card.Text>

								<div className="lab-card-row">
									<Form.Control
										className="input-primary lab-search"
										placeholder="Buscar Categoría..."
										type="text"
										style={{
											width: "220px",
											marginBottom: ".5rem",
										}}
										value={categoryInputValue}
										onChange={(e) => setCategoryInputValue(e.target.value)}
									/>
									<div
										style={{
											display: "flex",
											alignItems: "baseline",
											justifyContent: "center",
											gap: ".3rem",
										}}
									>
										<Form.Check className="category-option"
											type="switch"
											label="Ver todo"
											checked={showAllCategories}
											onChange={() => setShowAllCategories((prev) => !prev)} 
										/>


									</div>
								</div>
								<h5 className="category-lab-title">Seleccione una opción: </h5>
								<div className="add-category-lab">
									{/* Checkbox para habilitar la selección de categorías */}
									<Form.Check className="category-option"
										type="checkbox"
										label="Agregar por categoría"
										checked={showAddByCategory}
										onChange={(e) => handleEnableCategorySelection(e.target.checked, "addByCategory")}
									/>

									<Form.Check className="category-option"
										type="checkbox"
										label="Seleccionar todas"
										checked={selectedCategories.length === allCategories.length && allCategories.length > 0} // Si todas las categorías están seleccionadas
										onChange={(e) => handleEnableCategorySelection(e.target.checked, "selectAll")}
									/>
								</div>

								{/* Mostrar categorías*/}
								<div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "8px", maxHeight: "400px", overflowY: "auto", marginBottom: "30px" }}>
									{allCategories
										// Mostrar todas las categorías si el switch "Ver todo" está activado o si hay búsqueda activa
										.filter((category) => showAllCategories || categoryInputValue.trim() !== "")
										// Filtrar por el texto de búsqueda
										.filter((category) => category.name.toLowerCase().includes(categoryInputValue.toLowerCase()))
										.map((category) => (
											<InputGroup key={category._id} style={{ flexWrap: "nowrap" }}>
												<InputGroup.Checkbox
													checked={selectedCategories.some((selected) => selected._id === category._id)}
													onChange={() => handleCategorySelection(category)}
													disabled={!enableCategorySelection} // Deshabilitar si no está permitido seleccionar categorías
												/>
												<InputGroup.Text
													style={{
														whiteSpace: "normal",
														width: "100%",
														justifyContent: "flex-start",
													}}
												>
													{category.name}
												</InputGroup.Text>
											</InputGroup>
										))}
								</div>

								{/* Mostrar productos relacionados con las categorías seleccionadas */}
								{selectedCategories.length > 0 && (
									<div style={{ width: "100%" }}>
										<h4 className="title-select-category">Productos Relacionados</h4>
										<div className="chip-category-select">
											{relatedProducts
											.filter((product) => !otherProductsAdded.some((otherProductsAdded) => otherProductsAdded._id === product._id)) // Filtrar productos ya agregados
											.map((product) => (
												<Chip
													key={product._id}
													label={product.name}
													variant="outlined"
													onDelete={() => handleDeleteRelatedProduct(product)} // Elimina el producto relacionado
												/>
											))}
											{/* Muestra tags relacionados */}
											{relatedTags
											.filter((tag) => !otherProductTagsAdded.some((otherProductTagsAdded) => otherProductTagsAdded._id === tag._id)) // Filtrar productos ya agregados
											.map((tag) => (
												<Chip
													key={tag._id}
													label={tag.name}
													variant="outlined"
													onDelete={() => handleDeleteRelatedTag(tag)} // Elimina el tag relacionado
												/>
											))}
										</div>
										

										{/* Botón par confirmas los productos relacionados */}
										{(relatedProducts.length > 0 || relatedTags.length) && (

											<div className="btn-category-lab">
												<Tooltip title="Agregar productos seleccionados" arrow>

													<Button
														className="btn-new-provider"
														onClick={confirmRelatedProductsAndTags}
													>
														<img
															className="icon-new-provider"
															src={IconAdd}
															alt="agregar"
														></img>

													</Button>
												</Tooltip>
											</div>
										)}
									</div>
								)}

							</Card.Body>
						</Card>
						{/* Tabla de productos agregados */}
						{/* filtro para buscar productos agregados */}
						<Form.Control
							type="text"
							placeholder="Buscar en productos agregados..."
							value={searchValueAdded}
							onChange={(e) => setSearchValueAdded(e.target.value)}
							style={{ marginBottom: "10px" }}
						/>
						{productsAdded.length || otherProductsAdded.length || productTagsAdded.length || otherProductTagsAdded.length ? (
							<Card className="input--primary" style={{ width: "100%", maxHeight: "300px", overflow: "auto" }}>
								<Card.Header as="h6">Productos Agregados</Card.Header>
								<Card.Body>

									{/* Sección Bioquímicos */}
									{(productsAdded.length > 0 || productTagsAdded.length > 0) && (
										<>
											<Form.Label>Bioquímicos:</Form.Label>
											<Form.Group>
												{filteredProductsAdded.length > 0 && (
													<>
														{filteredProductsAdded.map((product) => (
															<div
																key={product._id}
																style={{
																	display: "flex",
																	textAlign: "start",
																	justifyContent: "space-between",
																	alignItems: "center",
																	marginBottom: "0.5rem",
																}}
															>
																<CloseButton
																	onClick={() => setProductsAdded((prev) => prev.filter((prevProduct) => prevProduct._id !== product._id))}
																	className="remove-button"
																/>
																<div style={{ flexGrow: "1" }}>{product.name}</div>
															</div>
														))}
													</>
												)}


												{productTagsAdded.length > 0 && (
													<>
														{productTagsAdded.map((tag) => (
															<div
																key={tag._id}
																style={{
																	display: "flex",
																	textAlign: "start",
																	justifyContent: "space-between",
																	alignItems: "center",
																	marginBottom: "0.5rem",
																}}
															>
																<CloseButton
																	onClick={() => setProductTagsAdded((prev) => prev.filter((prevTag) => prevTag._id !== tag._id))}
																	className="remove-button"
																/>
																<div style={{ flexGrow: "1" }}>{tag.name}</div>
															</div>
														))}
													</>
												)}
											</Form.Group>
										</>
									)}

									{/* Separador */}
									{productsAdded.length > 0 && otherProductsAdded.length > 0 && <hr />}

									{/* Sección Varios */}
									{(otherProductsAdded.length > 0 || otherProductTagsAdded.length > 0) && (
										<>
											<Form.Label>Varios:</Form.Label>
											<Form.Group>
												{filteredOtherProductsAdded.length > 0 && (
													<>
														{filteredOtherProductsAdded.map((otherProduct) => (
															<div
																key={otherProduct._id}
																style={{
																	display: "flex",
																	textAlign: "start",
																	justifyContent: "space-between",
																	alignItems: "center",
																	marginBottom: "0.5rem",
																}}
															>
																<CloseButton
																	onClick={() =>
																		setOtherProductsAdded((prev) =>
																			prev.filter((prevProduct) => prevProduct._id !== otherProduct._id)
																		)
																	}
																	className="remove-button"
																/>
																<div style={{ flexGrow: "1" }}>{otherProduct.name}</div>
															</div>
														))}
													</>
												)}

												{otherProductTagsAdded.length > 0 && (
													<>
														{otherProductTagsAdded.map((tag) => (
															<div
																key={tag._id}
																style={{
																	display: "flex",
																	textAlign: "start",
																	justifyContent: "space-between",
																	alignItems: "center",
																	marginBottom: "0.5rem",
																}}
															>
																<CloseButton
																	onClick={() =>
																		setOtherProductTagsAdded((prev) => prev.filter((prevTag) => prevTag._id !== tag._id))}
																	className="remove-button"
																/>
																<div style={{ flexGrow: "1" }}>{tag.name}</div>
															</div>
														))}
													</>
												)}
											</Form.Group>
										</>
									)}
								</Card.Body>
							</Card>
						) : null}



						<div className="button-container-lab" style={{ margin: "5%" }}>
							<button
								className="btn-lab-text"
								style={{ width: "140px" }}
								children="Cancelar"
								onClick={() => {
									navigate("/laboratory");
								}}
							/>
							<button className="btn-lab" style={{ width: "140px" }} children="Guardar" type="submit" onClick={() => { }} />
						</div>
					</Form>
				</div>
			</div>
			{variable && (
				<AnimacionSuccessful
					variable={variable}
					setVariable={setVariable}
					setErrorBool={setErrorBool}
					avisomsg={avisomsg}
					errorBool={errorBool}
				/>
			)}
		</div>
	);
}
