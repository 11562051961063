import axios from "axios"
import Accordion from "react-bootstrap/Accordion"
import Cookies from "js-cookie"
import "../Modals.css"
import React, { Fragment, useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import ListItemCheck from "../Components/ListItemCheck"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function AcceptReductionModal({ show, handleClose, productsToAcceptReduction, handleSuccessfulMessage, handleErrorMessage, tabSection }) {
	const { productsToReduceLot, productsIrreducible, productsWithPoorData, productsNotFound, productsToReduce } = productsToAcceptReduction

	const [providerNames, setProvidersNames] = useState([])
	const [unitsNames, setUnitsNames] = useState([])
	const [categoriesNames, setCategoriesNames] = useState([])

	useEffect(() => {
		;(async () => {
			// Obtiene todos los nombres de los proveedores
			const token = Cookies.get("token")
			const responseProvider = await axios({
				method: "get",
				url: `${BACK_APP_URI}/proveedor`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const providers = responseProvider.data.provider.map(provider => {
				return {
					id: provider._id,
					name: provider.name,
				}
			})

			setProvidersNames(providers)

			// Obtiene todas las unidades
			const responseUnit = await axios({
				method: "get",
				url: `${BACK_APP_URI}/unidades`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const units = responseUnit.data.units.map(unit => {
				return {
					id: unit._id,
					name: unit.name,
				}
			})

			setUnitsNames(units)

			// Obtiene todos las categorías
			const responseCategories = await axios({
				method: "get",
				url: `${BACK_APP_URI}/categories`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const categories = responseCategories.data.categories.map(category => {
				return {
					id: category._id,
					name: category.name,
				}
			})

			setCategoriesNames(categories)
		})()
	}, [])

	const handleReduceProducts = async () => {
		try {
			if(tabSection === "Bioquimicos") {

				for (const productFromExcel of productsToReduceLot) {
					// Se obtiene data del lote
					const lotData = await axios({
						method: "get",
						url: `${BACK_APP_URI}/lotes/${productFromExcel.lotId}`,
					})

					// Resta de cantidad anterior con la nueva
					const newQuantity = lotData.data.quantity - Math.abs(productFromExcel.quantity)
					const newLotQuantity = lotData.data.lotQuantity - Math.abs(productFromExcel.lotQuantity)

					// Si el descuento es mayor a 0 solo se edita el lote
					if (newQuantity >= 0) {
						await axios({
							method: "put",
							url: `${BACK_APP_URI}/lotes/edit/${productFromExcel.lotId}`,
							data: { ...lotData.data, quantity: newQuantity, lotQuantity: newLotQuantity },
						})
					}
				}
				
			} else {
					
				for (const productFromExcel of productsToReduce) {

					await axios({
						method: "put",
						url: `${BACK_APP_URI}/otros_productos/edit/${productFromExcel.productId}`,
						data: { quantity: productFromExcel.oldQuantity - productFromExcel.quantity },
					})
				}
			}

			handleSuccessfulMessage("Descuento completo")
			handleClose()
		} catch (error) {
			handleErrorMessage("Error al descontar")
			handleClose()
		}
	}

	const ModalSections = [
		{
			title: "Lotes a Descontar",
			products: productsToReduceLot,
			available: true,
			showReduction: true,
		},
		{
			title: "Cantidad a Descontar",
			products: productsToReduce,
			available: true,
			showReduction: true,
		},
		{
			title: "No Reducible",
			products: productsIrreducible,
			available: false,
			showReduction: true,
		},
		{
			title: "Datos insuficientes",
			products: productsWithPoorData,
			available: false,
			showReduction: false,
		},
		{
			title: "No Encontrados",
			products: productsNotFound,
			available: false,
			showReduction: false,
		},
	]

	return (
		<Modal
			size="lg"
			show={show}
			onHide={handleClose}
			backdrop="static"
		>
			<Modal.Header>
				<Modal.Title className="italic">Descuento de Productos</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ModalSections.map((section, index) => {
					// No se muestra si no tiene productos
					if (!section.products?.length > 0) return

					return (
						<Fragment key={section.title}>
							<div className={`${!section.available && "color-gray"} flex flex-column gap-2`}>
								<h3 className={`${section.available ? "market-green" : "market-red"} market`}>{section.title}</h3>
								{section.products?.map((product, index) => {
									return (
										<>
											<div key={product.productCode}>
												<Accordion defaultActiveKey="0">
													<Accordion.Item>
														<Accordion.Header>
															<div className="flex flex-column gap-2">
																<div>
																	Producto: {product.productCode} - {product.name}
																</div>
																{section.showReduction && (
																	<>
																		<div>
																			Cantidad a Descontar: <b className="color-red">{product.quantity}</b>
																		</div>
																		{tabSection === "Bioquímicos" && (
																			<div>
																				Lotes a Descontar: <b className="color-red">{product.lotQuantity}</b>
																			</div>
																		)}
																	</>
																)}
															</div>
														</Accordion.Header>
														<Accordion.Body>
															<ul>
																{tabSection === "Bioquimicos" && (
																	<>
																		<ListItemCheck property={product.lotCode} title={"Código Lote:"} />
																		<li className={!product.acquireDate ? "color-red" : ""}>Fecha Ingreso: {product.acquireDate && new Date(product.acquireDate).toLocaleDateString()}</li>
																		<li className={!product.expireDate ? "color-red" : ""}>Fecha Vencimiento: {product.expireDate && new Date(product.expireDate).toLocaleDateString()}</li>
																		<ListItemCheck property={product.quantity} title={"Cantidad:"} />
																		<li className={product.lotQuantity === undefined ? "color-red" : ""}>Cantidad Lotes: {product.lotQuantity}</li>
																		<hr />
																	</>
																)}
																<ListItemCheck property={product.productCode} title={"Código Producto:"} />
																<ListItemCheck property={product.name} title={"Nombre:"} />

																<li className={!product.providerId ? "color-red" : ""}>Proveedor: {providerNames.find(provider => provider.id == product.providerId)?.name}</li>
																<li className={!product.unitTypeId ? "color-red" : ""}>Tipo Unidad: {unitsNames.find(unit => unit.id == product.unitTypeId)?.name}</li>
																{tabSection === "Bioquimicos" ? (
																	<>
																		<ListItemCheck property={product.ubn} title={"Nbu:"} />
																		<ListItemCheck property={product.brand} title={"Marca:"} />
																	</>
																) : (
																	<>
																		<ListItemCheck property={product.quantity} title={"Cantidad:"} />
																		<li className={!product.categoryId ? "color-red" : ""}>Categoría: {categoriesNames.find(category => category.id == product.categoryId)?.name}</li>
																	</>
																)}
																{product.weightOrVolume && (
																	<ListItemCheck property={product.weightOrVolume} title={"Peso o Volumen:"} />
																)}
															</ul>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</div>
										</>
									)
								})}
							</div>
							{index != ModalSections.length - 1 && <hr />}
						</Fragment>
					)
				})}
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex">
					{productsToReduceLot?.length > 0 || productsToReduce?.length > 0 ? (
						<Button
							style={{ width: "10rem", marginRight: "1rem" }}
							className="btn--primary"
							variant="primary"
							onClick={handleReduceProducts}
						>
							Descontar
						</Button>
					) : null}

					<Button
						variant="secondary"
						onClick={handleClose}
					>
						Cerrar
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
