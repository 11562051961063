import axios from "axios"
import Cookies from "js-cookie"
import React, { Fragment, useEffect, useState } from "react"
import { Accordion, Button, Modal } from "react-bootstrap"
import ListItemCheck from "../Components/ListItemCheck"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function AcceptEditionModal({ show, handleClose, productsToAcceptEdition, handleSuccessfulMessage, handleErrorMessage, tabSection }) {
	const { productsWithModification, productsWithoutModification, productsNotFound, productsWithPoorData } = productsToAcceptEdition

	const [providerNames, setProvidersNames] = useState([])
	const [unitsNames, setUnitsNames] = useState([])
	const [tagsNames, setTagsNames] = useState([])
	const [categoriesNames, setCategoriesNames] = useState([])

	useEffect(() => {
		;(async () => {
			// Obtiene todos los nombres de los proveedores
			const token = Cookies.get("token")
			const responseProvider = await axios({
				method: "get",
				url: `${BACK_APP_URI}/proveedor`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const providers = responseProvider.data.provider.map(provider => {
				return {
					id: provider._id,
					name: provider.name,
				}
			})

			setProvidersNames(providers)

			// Obtiene todas las unidades
			const responseUnit = await axios({
				method: "get",
				url: `${BACK_APP_URI}/unidades`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const units = responseUnit.data.units.map(unit => {
				return {
					id: unit._id,
					name: unit.name,
				}
			})

			setUnitsNames(units)

			// Obtiene todos los tags
			const responseTag = await axios({
				method: "get",
				url: `${BACK_APP_URI}/tags`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const tags = responseTag.data.tags.map(tags => {
				return {
					id: tags._id,
					name: tags.name,
				}
			})

			setTagsNames(tags)

			// Obtiene todos las categorías
			const responseCategories = await axios({
				method: "get",
				url: `${BACK_APP_URI}/categories`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const categories = responseCategories.data.categories.map(category => {
				return {
					id: category._id,
					name: category.name,
				}
			})

			setCategoriesNames(categories)
		})()
	}, [])

	// La edición de productos fue aceptada y se manda a la base de datos
	const handleUpdateProducts = async () => {
		try {
			if (tabSection == "Bioquimicos") {
				for (const product of productsWithModification) {
					// Convierte el array de propiedades en un objeto
					let productDataToSend = {}

					product.props.forEach(prop => {
						productDataToSend[prop.propertyName] = prop.newValue
					})

					const { lotCode, acquireDate, expireDate, quantity, code, name, provider, unitType, brand, ubn, lotQuantity, tag, weightOrVolume } = productDataToSend

					await axios({
						method: "put",
						url: `${BACK_APP_URI}/productos/edit/${product.productId}`,
						data: {
							name: name,
							code: code || 1,
							brand: brand,
							unitType: unitType,
							provider: provider,
							ubn: ubn,
							tag: tag,
							weightOrVolume: weightOrVolume
						},
					})

					// Editar lote solo si contiene
					if (product?.lotId) {
						await axios({
							method: "put",
							url: `${BACK_APP_URI}/lotes/edit/${product.lotId}`,
							data: {
								lotNumber: lotCode,
								expireDate: expireDate,
								acquireDate: acquireDate,
								quantity: quantity,
								provider: provider,
								product: product.productId,
								lotQuantity: lotQuantity,
							},
						})
					}
				}
			} else {
				for (const otherProduct of productsWithModification) {
					// Convierte el array de propiedades en un objeto
					let dataToSend = {}

					otherProduct.props.forEach(prop => {
						dataToSend[prop.propertyName] = prop.newValue
					})

					await axios({
						method: "put",
						url: `${BACK_APP_URI}/otros_productos/edit/${otherProduct.id}`,
						data: dataToSend,
					})
				}
			}

			handleSuccessfulMessage("Edición completa")
			handleClose()
		} catch (error) {
			handleErrorMessage("Error al editar")
			handleClose()
		}
	}

	const ModalSections = [
		{
			title: "Productos a Editar",
			products: productsWithModification,
			available: true,
		},
		{
			title: "Productos sin Modificación",
			products: productsWithoutModification,
			available: false,
		},
		{
			title: "Productos No Encontrados",
			products: productsNotFound,
			available: false,
		},
		{
			title: "Datos insuficientes",
			products: productsWithPoorData,
			available: false,
		},
	]

	return (
		<Modal
			size="lg"
			show={show}
			onHide={handleClose}
			backdrop="static"
		>
			<Modal.Header>
				<Modal.Title className="italic">Edición de Productos</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ModalSections.map((section, index) => {
					// No se muestra si no tiene productos
					if (!section.products?.length > 0) return

					return (
						<div key={section.title}>
							<div className={`${!section.available && "color-gray"} flex flex-column gap-2`}>
								<h3 className={`${section.available ? "market-green" : "market-red"} market`}>{section.title}</h3>

								{section.title === "Productos a Editar"
									? // Muestra diferencias de campos
									  section.products?.map(({ props: product }) => {
											return (
												<>
													<div key={product.productCode}>
														<Accordion defaultActiveKey="0">
															<Accordion.Item>
																<Accordion.Header>
																	<div className="flex flex-column gap-2">
																		<div>
																			Producto: {product[0].oldValue} - {product[1].oldValue}
																		</div>
																	</div>
																</Accordion.Header> 
																<Accordion.Body>
																
																	{product.some(prop => prop.state === "Edit") ? (
																		<div className="flex flex-column gap-2">
																			{product.map(prop => {
																				if (prop.state === "Keep") return

																				// Traduce el nombre de la propiedad
																				let newPropertyName

																				switch (prop.propertyName) {
																					case "code":
																						newPropertyName = "Código Producto"
																						break
																					case "name":
																						newPropertyName = "Nombre"
																						break
																					case "quantity":
																						newPropertyName = "Cantidad"
																						break
																					case "unitTypeId":
																						newPropertyName = "Tipo Unidad"
																						break
																					case "expireDate":
																						newPropertyName = "Fecha Vencimiento"
																						break
																					case "lotNumber":
																						newPropertyName = "Código Lote"
																						break
																					case "ubn":
																						newPropertyName = "Nbu"
																						break
																					case "brand":
																						newPropertyName = "Marca"
																						break
																					case "providerId":
																						newPropertyName = "Proveedor"
																						break
																					case "lotQuantity":
																						newPropertyName = "Cantidad Lotes"
																						break
																					case "acquireDate":
																						newPropertyName = "Fecha Ingreso"
																						break
																					case "category":
																						newPropertyName = "Categoría"
																						break
																					case "weightOrVolume":
																						newPropertyName = "Peso o Volumen"
																						break
																					case "tag":
																						newPropertyName = "Etiqueta"
																						break
																					default:
																						newPropertyName = prop.propertyName
																						break
																				}

																				let oldValue = prop.oldValue
																				let newValue = prop.newValue

																				// Busca el nombre del proveedor
																				if (prop.propertyName == "provider") {
																					oldValue = providerNames.find(provider => provider.id == oldValue).name
																					newValue = providerNames.find(provider => provider.id == newValue).name
																				}

																				// Busca la unidad
																				if (prop.propertyName == "unitType") {
																					oldValue = unitsNames.find(unit => unit.id == oldValue).name
																					newValue = unitsNames.find(unit => unit.id == newValue).name
																				}

																				// Busca la etiqueta
																				if (prop.propertyName == "tag") {
																					oldValue = tagsNames.find(tag => tag.id == oldValue).name
																					newValue = tagsNames.find(tag => tag.id == newValue).name
																				}

																				// Busca la categoría
																				if (prop.propertyName == "category") {
																					oldValue = categoriesNames.find(category => category.id == oldValue).name
																					newValue = categoriesNames.find(category => category.id == newValue).name
																				}

																				// Formatea la fecha
																				if (prop.propertyName == "expireDate" || prop.propertyName == "acquireDate") {
																					let oldValueDate = new Date(oldValue)
																					let newValueDate = new Date(newValue)

																					oldValue = `${oldValueDate.getDate()}/${oldValueDate.getMonth() + 1}/${oldValueDate.getFullYear()}`
																					newValue = `${newValueDate.getDate()}/${newValueDate.getMonth() + 1}/${newValueDate.getFullYear()}`
																				}

																				return (
																					<div
																						style={{
																							display: "flex",
																							gap: ".3rem",
																						}}
																						key={newPropertyName}
																					>
																						<div>{newPropertyName}:</div>{" "}
																						<div>
																							<b style={{ color: "#EE5858" }}>{oldValue}</b> → <b style={{ color: "#65D624" }}>{newValue}</b>
																						</div>
																					</div>
																				)
																			})}
																		</div>
																	) : (
																		<div>No hay modificaciones</div>
																	)}
																</Accordion.Body>
															</Accordion.Item>
														</Accordion>
													</div>
												</>
											)
									  })
									: 
									section.products?.map((data) => {
											const { product, lot } = tabSection === "Bioquimicos" ? data : { product: data }
											return (
												<>
													<div key={product.code}>
														<Accordion defaultActiveKey="0">
															<Accordion.Item>
																<Accordion.Header>
																	<div className="flex flex-column gap-2">
																		<div>
																			Producto: {product.code} - {product.name}
																		</div>
																	</div>
																</Accordion.Header>
																<Accordion.Body>
																	<ul>
																		{tabSection === "Bioquimicos" && Object.keys(lot).length > 0 && (
																			<>
																				<ListItemCheck property={lot.lotNumber} title={"Código Lote:"} />
																				<li className={!lot.acquireDate ? "color-red" : ""}>Fecha Ingreso: {lot.acquireDate && new Date(lot.acquireDate).toLocaleDateString()}</li>
																				<li className={!lot.expireDate ? "color-red" : ""}>Fecha Vencimiento: {lot.expireDate && new Date(lot.expireDate).toLocaleDateString()}</li>
																				<ListItemCheck property={lot.quantity} title={"Cantidad:"} />
																				<ListItemCheck property={lot.lotQuantity} title={"Cantidad Lotes:"} />
																				<hr />
																			</>
																		)}
																		<ListItemCheck property={product.code} title={"Código Producto:"} />
																		<ListItemCheck property={product.name} title={"Nombre:"} />
																		<li className={!product.provider ? "color-red" : ""}>Proveedor: {providerNames.find(provider => provider.id == product.provider)?.name}</li>
																		<li className={!product.unitType ? "color-red" : ""}>Tipo Unidad: {unitsNames.find(unit => unit.id == product.unitType)?.name}</li>
																		{product.tag !== null && (
																			<ListItemCheck property={tagsNames.find(tag => tag.id == product.tag)?.name} title={"Etiqueta:"} />
																			
																		)}
																		{tabSection === "Bioquimicos" ? (
																			<>
																				<ListItemCheck property={product.ubn} title={"Nbu:"} />
																				<ListItemCheck property={product.brand} title={"Marca:"} />
																			</>
																		) : (
																			<>
																				<ListItemCheck property={product.quantity} title={"Cantidad:"} />
																				<li className={!product.category ? "color-red" : ""}>Categoría: {categoriesNames.find(category => category.id == product.category)?.name}</li>
																			</>
																		)}
																		{product.weightOrVolume && (
																			<ListItemCheck property={product.weightOrVolume} title={"Peso o Volumen:"} />
																		)}
																	</ul>
																</Accordion.Body>
															</Accordion.Item>
														</Accordion>
													</div>
												</>
											)
									  })
								}
							</div>
							{index != ModalSections.length - 1 && <hr />}
						</div>
					)
				})}
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex">
					{productsWithModification?.length > 0 ? (
						<Button
							style={{ width: "10rem", marginRight: "1rem" }}
							className="btn--primary"
							variant="primary"
							onClick={handleUpdateProducts}
						>
							Aceptar cambios
						</Button>
					) : null}
					<Button
						variant="secondary"
						onClick={handleClose}
					>
						Cerrar
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
